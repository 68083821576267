.container {
  position: relative;
  width: 893px;
  height: 584px;
  padding: 40px 87px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  .header {
    text-align: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 20px;

  }
  .input {
    height: 52px;
  }
  .buttonWrap {
    width: 100%;
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .button {
    height: 44px;
    width: calc(50% - 6px);
    background: #1c1c1c;
  }
}
