.clientsMenu {
  width: 100%;
  height: calc(100vh - 256px);
  &_header {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: rgba(255, 255, 255, 0.8);
    margin-bottom: -18px;
    margin-top: 10px;
  }
}
.clientsMenuWrap {
  height: calc(100vh - 334px) !important;
  overflow-y: scroll;
}
.customSubmenu {

  >li {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 0 !important;
  }
  ul {
    background: transparent !important;
    li {
      padding-left: 36px !important;
    }
  }
}
.iconWrapper {
  width: 24px;
  height: 24px;
  background: #e3f5ff;
  padding: 4px;
  border-radius: 8px;
  min-width: 3px !important;
  // display: flex !important;
  // align-items: center;
  // justify-items: center;
  // span {
  //   display: inline-flex !important;
  //   width: 7px !important;
  // }
}
