.sideBar {
  padding: 28px;
  height: 100%;
  background: #1c1c1c;
  min-width: 220px;
  .avatarWrapper {
    margin-bottom: 30px;
    .name {
      margin-left: 5px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }
  }

}
