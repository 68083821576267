.container {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  min-width: 600px;
  div:last-of-type {
    text-align: right;
  }
  .header {
    text-align: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 20px;
    span {
      margin-right: 8px;
    }
  }
}
