.totalUsers {
  position: relative;
  padding: 20px;
  width: 100%;
  overflow: hidden;
  // min-width: 662px;
  max-width: 881px;
  height: 320px;
  max-height: 320px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 16px;
  margin: 0 auto;
  margin-top: 24px;
  .tooltipWrapper {
    position: relative;
    .tooltip {
      border: none;
      padding: 8px;
      background: rgba(0, 0, 0, 0.8);
      border-radius: 8px;
      justify-content: flex-start;
      align-items: center;
      display: flex;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      &_point {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: 8px;
      }
      &_circle {
        position: fixed;
        bottom: -20px;
        left: calc(50% - 7px);
        width: 12px;
        height: 12px;
        border-radius: 50%;
        border: 3px solid #ffffff;
      }
    }
  }

}
.legend {
  display: flex;
  width: 100%;
  height: 20px;
  font-family: 'Inter', sans-serif;
  &_totalUsers {
    display: inline-block;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    margin-right: 16px;
  }
  &_totalViews {
    display: inline-block;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.4);
    padding-right: 16px;
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    margin-right: 16px;
  }
  .total {
    margin-left: auto;
    margin-right: 0px;
    margin-right: 20px;
  }
  &_views {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-width: 35px;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    margin-right: 20px;
    text-transform: capitalize;

    .point {
      background-color: #ffffff;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      margin-right: 5px;
    }
  }
  .dots {
    // margin-left: auto;
    display: inline-flex;
    height: 20px;
    vertical-align: middle;
    margin-top: -3px;
    margin-left: 20px;
  }
}
