
.blueBlock {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 17px;
  gap: 8px;
  overflow: hidden;
  // min-width: 278.67px;
  width: 100%;
  min-height: 112px;
  max-height: 112px;
  background: #e3f5ff;
  border-radius: 16px;
  flex: none;
  order: 0;
  flex-grow: 1;
  color: #000;
  margin-right: 28px;
  &:last-of-type {
    margin-right: 0;
  }
  &_header {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #1c1c1c;
    height: 20px;
  }
  &_body {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #1c1c1c;
    width: 100%;
    text-align: center;
    span {
      text-transform: uppercase;
    }
  }
}
