.rdrDateDisplayWrapper {
  background-color: #333030;
  display: none;

}


.rdrCalendarWrapper {
  position: relative;
  width: 100%;
    max-width: 881px;
    min-width: 881px;
    height: 448px;
    padding: 32px 56px 0px;
  display: flex;
  justify-content: flex-start;
  border: 1px solid #BDBDBD;
  box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.05);
  border-radius: 30px;
  background: #333030;
    font-family: 'Inter', sans-serif;
}

.rdrMonths {
  margin: 0 auto;
}

.rdrMonthAndYearWrapper {
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: calc(100% - 233px);
    margin-left: 60px;
}
.rdrMonthAndYearPickers {
  display: none;
}
.rdrMonthAndYearPickers>span {
  color: #FFFFFF;
}
.rdrMonthPicker>select,
.rdrYearPicker>select,
.rdrMonthPicker>select>option,
.rdrYearPicker>select>option {
  background-color: #1C1C1C;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #858585;
  min-width: 70px;
  border-radius: 14px;
}


.rdrDateDisplayItem {
  background-color: #1C1C1C;
}

.rdrDayDisabled {
  background-color: #1C1C1C
}

.rdrDayNumber span {
  color: #FFFFFF;
  font-size: 16px;
}

.rdrDayPassive>.rdrDayNumber span {
  color: #858585;
}

.rdrMonthName {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #FFFFFF;
}

.rdrWeekDay {
  font-weight: 500;
  font-size: 14px;
  height: 48px;
    line-height: 48px;
  text-align: center;
  color: #858585;
}

.rdrDay:not(.rdrDayPassive) .rdrInRange~.rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrStartEdge~.rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrEndEdge~.rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrSelected~.rdrDayNumber span {
  color: #1C1C1C;
}

.rdrDayDisabled {
  cursor: not-allowed;
}

.rdrDay {
  height: 3.5rem;
  height: 48px;
}

.rdrStartEdge {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  left: 2px;
}

.rdrEndEdge {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  right: 2px;
}

.rdrSelected {
  border-radius: 8px;
}

.rdrDayStartOfMonth .rdrInRange,
.rdrDayStartOfMonth .rdrEndEdge,
.rdrDayStartOfWeek .rdrInRange,
.rdrDayStartOfWeek .rdrEndEdge {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  left: 2px;
}

.rdrDayEndOfMonth .rdrInRange,
.rdrDayEndOfMonth .rdrStartEdge,
.rdrDayEndOfWeek .rdrInRange,
.rdrDayEndOfWeek .rdrStartEdge {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  right: 2px;
}

.rdrCalendarWrapper:not(.rdrDateRangeWrapper) .rdrDayHovered .rdrDayNumber:after {
  border-radius: 10px !important;
}

.rdrSelected {
  border-radius: 10px;
}
