.companyInfoWrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  .headerWrapp {
    width: 100%;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    margin-top: 20px;
    .headerText {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
    }
    .headerButtons {
      display: flex;

      .image {
        display: inline-flex;
        margin-left: 16px;
        cursor: pointer;
      }
    }
  }
  .venueWrapp {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 12px 0;
    .venueNameWrap {
      display: flex;
      align-items: center;
      justify-items: center;
      &_name {
        display: inline-flex;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }
      &_image {
        display: inline-flex;
        margin-right: 8px;
      }
    }
    .venueIdWrap {
      display: flex;
      align-items: center;
      justify-items: center;
      &_name {
        display: inline-flex;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }
      &_image {
        display: inline-flex;
        margin-left: 20px;
        cursor: pointer;
      }
    }
  }
}
