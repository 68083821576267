.overview {
  min-width: 500px;
  max-width: 881px;
  width: 100%;
  margin: 0 auto;
  .blockLang {
    font-size: 18px;
  }
  &_header {
    display: flex;
    width: 100%;
    max-width: 881px;
    margin: 0 auto;
    flex-direction: column;
  }
  &_button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    max-width: 322px;
    text-align: center;
    height: 52px;
    padding: 4px 8px;
    padding-right: 24px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    span {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 24px;
      display: inline-flex;
      text-align: center;
      margin: 0;
      margin-inline-start: 0 !important;
    }
  }
  &_views {
    width: 100%;
    max-width: 881px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }
}
