

.boundaryErrorWrap {
  border-radius: 4px;
  width: 100%;
  height: 100%;
  .boundaryError {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .main {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;

      &_text {
        text-align: center;
        font-size: 14px;
        line-height: 1.5;
        color: #fa248c;
        font-family: 'Tahoma', sans-serif;
      }
    }
  }
}

