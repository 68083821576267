.clientTrafficWrapper {
  width: 100%;
  overflow: hidden;
  // min-width: 202px;
  height: 320px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 16px;
  margin-top: 24px;
  padding: 24px;
  padding-right: 0px;
  .header {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    height: 20px;
  }
  .body {
    display: flex;
    flex-direction: column;
    height: 272px;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 0;
    .clientWrap {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      padding: 10px 0;
      .name {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        width: 50%;
        overflow-wrap: break-word;
        overflow: hidden;
        margin-right: 4px;
      }
      .lineWrap {
        width: 100%;
      }
      .line {
        background: #c6c7f8;
        border-radius: 0px 4px 4px 0px;
        height: 6px;
      }
      .traffic {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #c6c7f8;
        min-width: 20px;
        max-width: 20%;
        padding-left: 4px;
        overflow: hidden;
      }
    }
  }
}
