.container {
  position: relative;
  height: 300px;
  padding: 40px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-width: 506px;
  .header {
    text-align: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    margin: 0 auto;
    margin-bottom: 20px;
    width: 367px;
    margin-top: 50px;

  }
  .input {
    margin-bottom: 20px;
    height: 52px;
  }
  .buttonsWrap {
    width: 100%;
    margin-top: auto;

  }
  .button {
    height: 44px;
    width: 100%;
    display: block;
    margin: 0 auto !important;
    background-color: ' #F2994A' !important;
    font-weight: 600;
    &_delete {
      background-color: #1c1c1c !important;
    }
  }
}
