.visitsWrapper {
  width: 100%;
  height: 200px;
  position: relative;
  .label {
    position: absolute;
    top: 52px;
    left: 78px;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;

  }
  .tooltip {
    background: rgba(0, 0, 0, 0.8);
    border-radius: 8px;
    padding: 8px;
    justify-content: flex-start;
    align-items: center;
    display: flex;
    &_point {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-right: 8px;
    }
  }
}
