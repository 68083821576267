.viewsWrapper {
  // min-width: 432px;
  width: 100%;
  overflow: hidden;
  // max-width: 432px;
  height: 273px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 16px;
  margin-top: 34px;
  padding: 24px;
  .views {
    &_header {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    }
  }
}

.tooltip {
  border: none;
  padding: 8px;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 8px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

