.rightBar {
  padding: 28px;
  background: #1c1c1c;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
  min-width: 280px;
}
