

.icon {
  &_btn {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}
.btnType {
  display: flex;
  align-items: center;
}
.contentTooltip {
  border-radius: 14px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  text-align: left;
  letter-spacing: 0.5px;
}

.contentTooltip_black {
  min-width: 50px !important;
  max-width: 200px !important;
  border-radius: 14px;
  color: #ffffff;
  font-size: 10px;
  line-height: 15px;
}

.contentTooltip_white {
  min-width: 50px !important;
  max-width: 246px !important;

  color: #4d4476;
  font-size: 13px;
  line-height: 19px;
  box-shadow: 0px 6px 15px rgba(155, 94, 94, 0.1);
}
.more {
  display: block;
}
