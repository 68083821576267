.navigation {
  .list {
    border: none !important;
    &_item {
      // height: 20px;
      // line-height: 20px;
      &::marker {
        color: rgba(255, 255, 255, 0.4);;
      }
      a {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #ffffff;
      }

    }
  }
}
