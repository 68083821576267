$color-text: #2a2a2a;
$color-button: rgba(0, 204, 135, 1);

$white: #ffffff;
$black: #000000;
$red500: rgba(255, 44, 24, 1);

$fontBody: rgba(246, 247, 250, 1);


$font10: rgba(227, 232, 243, 1);

$font15: rgba(212, 217, 231, 1);
$font25: rgba(185, 193, 212, 1);
$font50: rgba(139, 150, 178, 1);
$font60: rgba(122, 132, 167, 1);
$font80: rgba(99, 102, 136, 1);
$font90: rgba(157, 150, 184, 1);

$font95: rgba(122, 113, 177, 1);
$font100: rgba(105, 97, 158, 1);
$font200: rgba(77, 68, 118, 1);
$font350: rgba(70, 53, 98, 1);
$font400: rgba(49, 37, 75, 1);

$purpleA10: rgba(251, 251, 251, 1);
$purple25: rgba(240, 219, 255, 1);
$purple50: rgba(251, 251, 251, 1);
$purpleA50: rgba(237, 237, 246, 1);
$purple100: rgba(224, 224, 243, 1);
$purpleA100: rgba(224, 224, 243, 1);
$purple200: rgba(200, 153, 240, 1);
$purpleA200: rgba(207, 207, 237, 1);
$purpleA250: rgba(185, 185, 225, 1);
$purple300: rgba(154, 97, 210, 1);
$purple350: rgba(119, 70, 177, 1);
$purple350disabled: rgba(119, 70, 177, 0.3);
$purple400: rgba(105, 54, 166, 1);
$purple500: rgba(83, 25, 165, 1);
$purple600: rgba(66, 12, 141, 1);
$purple700: rgba(51, 11, 107, 1);

$pink25: rgba(255, 234, 234, 1);
$pink50: rgba(250, 214, 215, 1);
$pink300: rgba(255, 122, 160, 1);
$pink400: rgba(255, 78, 147, 1);
$pink500: rgba(250, 36, 140, 1);

$green25: rgba(224, 253, 232, 1);
$green50: rgba(201, 252, 215, 1);
$green100: rgba(149, 249, 186, 1);
$green200: rgba(95, 239, 162, 1);
$green300: rgba(56, 224, 150, 1);
$green350: rgba(38, 215, 137, 1);
$green400: rgba(0, 204, 135, 1);
$green400disabled: rgba(0, 204, 135, 0.3);
$green500: rgba(17, 183, 143, 1);
$green600: rgba(0, 175, 133, 1);
$green700: rgba(0, 146, 126, 1);

$blue: rgba(117, 117, 255, 1);
$blue50: rgba(218, 218, 255, 1);
$blue200: rgba(144, 145, 255, 1);
$blue500: rgba(53, 54, 245, 1);
$blue600: rgba(38, 39, 210, 1);

$modalbg: rgba(23, 19, 33, 0.8);
