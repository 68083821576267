@import 'styles/_variables';

.modalBg {
  font-family: 'Inter', sans-serif;
  background-color: $modalbg;
  inset: 0px;
  position: fixed;
  animation: modal 0.2s both;
  z-index: 1998;
  width: 100%;
  height: 100%;

  &_datePicker {
    background-color: transparent;
  }
  ::-webkit-scrollbar {
    width: 11px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(105, 97, 158, 1);
    border: 3px solid transparent;
    border-radius: 20px;
    background-clip: content-box;
  }
}

.modalBody {
  position: absolute;
  inset: 50% auto auto 50%;
  border: 1px solid rgb(204, 204, 204);
  outline: none;
  padding: 20px;
  margin-right: -50%;
  min-height: 100px;
  overflow: visible;
  padding: 0;

  background: $fontBody;
  box-shadow: 0px 5px 30px rgba(30, 22, 80, 0.5);
  border-radius: 4px;
  transform: translate(-50%, -50%);
}

