.loginWrap {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .header {
    color: #fff;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
  }
}
.login {
  width: 100%;
  max-width: 851px;
  height: 460px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #3d4145;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 12px;
  .input {
    height: 52px;
  }
  .button {
    width: 100%;
    border: none;
    height: 44px;
  }
  .inputError {
    color: #dc4446;
    margin-top: -20px;
  }
}

