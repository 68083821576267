.header {
  position: relative;
  flex: 0 0 auto;
  background: #1c1c1c;
  height: 72px;
  padding-inline: 50px;
  color: rgba(255, 255, 255, 0.85);
  line-height: 72px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;

  &_switchLeft {
    position: absolute;
    left: 10px;
    cursor: pointer;
    border-radius: 4px;
    box-shadow: inset 0 5px 5px -5px #1c1c1c, inset 0 -5px 5px -5px #1c1c1c;
    background-color: #fff;
    transform: scaleX(-1);
    &_on {
      transform: none;
    }
  }
  &_switchRight {
    position: absolute;
    right: 10px;
    cursor: pointer;
    border-radius: 4px;
    box-shadow: inset 0 5px 5px -5px #1c1c1c, inset 0 -5px 5px -5px #1c1c1c;
    background-color: #fff;
    &_on {
      transform: scaleX(-1);
    }

  }
  nav > ol > li > span > a {
    color: #fff;
  }
}
