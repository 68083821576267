@import 'styles/_variables';

html {
  box-sizing: border-box;
  width: 100vw;
  overflow-x: hidden;
  scrollbar-width: thin;
  font-family: 'Inter', sans-serif;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  // background-color: $fontBody;
  color: #fff;
  // overflow-y: overlay;
}

p,
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  padding: 0;
}

.app {
  font-family: 'Inter', sans-serif;
  height: 100vh;
  width: 100vw;
  background: #1c1c1c;
  color: #fff;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-thumb {
  background-color: #adb6c9;
  border: 3px solid transparent;
  border-radius: 20px;
  background-clip: content-box;
}
