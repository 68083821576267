.clients {
  min-width: 500px;
  max-width: 881px;
  width: 100%;
  margin: 0 auto;
  &_header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 auto;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    margin-top: 30px;
  }
  &_views {
    width: 100%;
    max-width: 881px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }
}
