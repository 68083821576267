.contentTooltip .react-tooltip-lite {
  border-radius: 4px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  text-align: left;
  letter-spacing: 0.5px;
  z-index: 1071;
}

.contentTooltip_black .react-tooltip-lite {
  min-width: 50px !important;
  max-width: 200px !important;

  color: #ffffff;
  font-size: 10px;
  line-height: 15px;
}

.contentTooltip_white .react-tooltip-lite {
  min-width: 50px !important;
  max-width: 246px !important;

  color: #4d4476;
  font-size: 13px;
  line-height: 19px;
  box-shadow: 0px 6px 15px rgba(155, 94, 94, 0.1);
}
