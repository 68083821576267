.enter {
  transform: translateX(-100%);
}

.enterActive {
  transform: translateX(0);
  transition: transform 350ms linear;
}

.exit {
  transform: translateX(0);
}

.exitActive {
  transform: translateX(-100%);
  transition: transform 350ms linear;
}
