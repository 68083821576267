.administrationWrap {
  min-width: 500px;
  max-width: 881px;
  width: 100%;
  margin: 0 auto;
  .modal {
    min-width: 600px;
    div {
      background: #1c1c1c !important;

    }
  }
  .administrationContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 881px;
    margin: auto;
  }
  .headerWrapp {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 36px;
    .header {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 48px;
      line-height: 72px;
    }
    .imageWrapp {
      width: 75px;
      height: 75px;
      background: rgba(0, 0, 0, 0.8);
      border: 8px solid #adbcbb;
      box-shadow: 0px 4px 4px 8px rgba(85, 72, 72, 0.25);
      border-radius: 50%;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      .image {
        display: inline-flex;
        width: 100%;
      }

    }
  }

}
