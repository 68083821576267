.container {
  position: relative;
  height: 300px;
  padding: 40px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-width: 600px;
  .header {
    text-align: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 20px;

  }
  .input {
    margin-bottom: 20px;
    height: 52px;
  }
  .button {
    height: 44px;
    width: 300px;
    display: block;
    margin: 0 auto !important;
    background: #1c1c1c;
  }
}
