@import 'styles/_variables';

.layoutWrap {
  width: 100%;
  height: 100%;
  display: flex;
}
.layoutMain {
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: width 350ms linear;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  &_bothOn {
    width: calc(100% - 500px);
    min-width: calc(100% - 500px);
  }
  &_rightOn {
    width: calc(100% - 280px);
    min-width: calc(100% - 280px);
    transition: all 350ms linear;
  }
  &_sideOn {
    width: calc(100% - 220px);
    min-width: calc(100% - 220px);
    transition: all 350ms linear;
  }
}
.sideBar {
  min-width: 0;
  width: 0;
  transition: all 350ms linear;
  &_active {
    min-width: 220px;
    width: 220px;
    transition: all 350ms linear;
  }
}
.rightBar {
  min-width: 0;
  width: 0;
  transition: all 350ms linear;
  &_active {
    min-width: 280px;
    width: 280px;
    transition: all 350ms linear;
  }
}
.content {
  flex: auto;
  min-height: 0;
  padding: 28px;
  background: #1c1c1c;
  height: calc(100% - 72px);
  overflow: scroll;
}

