@import 'styles/_variables';

.notFound {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: auto;
  height: 100%;
  width: 100%;
  padding: 80px;


  &_image {
    content: url('../../assets/svg/404page.svg');
    margin-bottom: 30px;
    max-width: 555px;
    max-width: 80vw;
    height: 50vh;
  }
  &_arrow_right {
    padding-top: 4px;
  }

  &_text {
    position: relative;
    font-weight: 900;
    font-size: 24px;
    line-height: 24px;
    font-family: 'Inter', sans-serif;
    color: #6936a6;
    margin-bottom: 14px;
    text-transform: uppercase;
    &_subtitle {
      text-align: center;
      display: inline-block;
      font-weight: 400;
      font-family: 'Inter', sans-serif;
      color: #4d4476;
      width: 100%;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 32px;
    }
  }
  &_btn {
    text-align: center;
    width: 140px;
    height: 40px;
    min-height: 40px;
    border-radius: 30px;
    background-color: #00cc87;
    color: #ffffff;
    font-size: 18px;
    line-height: 26px;
    font-family: 'Inter', sans-serif;
    text-align: center;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    span {
      margin-right: 8px;
      display: inline-block;
      padding-top: 4px;
    }

    &:hover,
    &:focus,
    &:focus-within,
    &:active {
      background-color: #26d789;
    }

  }
}
